import React from "react"
import Layout from "../components/Layout"
import infoStyles from "../styles/pages/info.module.scss"
import useSiteMetaData from "../static_queries/useSiteMetadata"

export default function Info() {
  const { infoData } = useSiteMetaData()
  return (
    <Layout page="info" bgColor={infoData.background_color}>
      <section className={infoStyles.info_blurb}>
        <h2>
          <div>
            <p>{infoData.description}</p>
          </div>
        </h2>
        <p>Find me here:</p>
        <ul>
          <li>
            <a href={`https://twitter.com/${infoData.contact.twitter_handle}`}>
              <img src="/twitter_icon.svg" className={infoStyles.icon} alt="twitter icon"/>
              <span>
              : @{infoData.contact.twitter_handle}
              </span>
            </a>
          </li>
          <li>
            <a href={`https://github.com/${infoData.contact.github_handle}`}>
              <img src="/github_icon2.svg" className={infoStyles.icon} alt="github icon" />
              <span>
                : {infoData.contact.github_handle}
              </span>
            </a>
          </li>
          <li>
            <a href={infoData.contact.linkedin_url}>
              <img src="/linkedin_icon.svg" className={infoStyles.icon} alt="linkedin icon" />
              <span>: Louis Gelinas</span>
            </a>
          </li>
          <li>
            <p>
              <img src="/place_icon.svg" className={infoStyles.icon} alt="place icon" />
              <span>: {infoData.contact.location}</span>
            </p>
          </li>
          <li>
          </li>
        </ul>
      </section>
    </Layout>
  )
}